define("discourse/plugins/chat/discourse/lib/textarea-interactor", ["exports", "@ember/destroyable", "@ember/object", "@ember/owner", "@ember/runloop", "@ember/service", "discourse/lib/textarea-text-manipulation", "discourse-common/utils/decorators"], function (_exports, _destroyable, _object, _owner, _runloop, _service, _textareaTextManipulation, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This class sole purpose is to provide a way to interact with the textarea
  // using the existing TextareaTextManipulation mixin without using it directly
  // in the composer component. It will make future migration easier.
  class TextareaInteractor extends _object.default {
    static #_ = (() => dt7948.g(this.prototype, "capabilities", [_service.service]))();
    #capabilities = (() => (dt7948.i(this, "capabilities"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    textManipulation;
    constructor(owner, textarea) {
      super(...arguments);
      (0, _owner.setOwner)(this, owner);
      this.textarea = textarea;
      this.element = textarea;
      this.textManipulation = new _textareaTextManipulation.default(owner, {
        textarea,
        // we don't need app events
        eventPrefix: null
      });

      // paste is using old native ember events defined on composer
      this.textarea.addEventListener("paste", this.textManipulation.paste);
      (0, _destroyable.registerDestructor)(this, instance => instance.teardown());
    }
    teardown() {
      this.textarea.removeEventListener("paste", this.textManipulation.paste);
    }
    set value(value) {
      this.textarea.value = value;
      const event = new Event("input", {
        bubbles: true,
        cancelable: true
      });
      this.textarea.dispatchEvent(event);
    }
    blur() {
      (0, _runloop.next)(() => {
        (0, _runloop.schedule)("afterRender", () => {
          this.textarea.blur();
        });
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "blur", [_decorators.bind]))();
    focus() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        ensureAtEnd: false,
        refreshHeight: true,
        addText: null
      };
      (0, _runloop.next)(() => {
        (0, _runloop.schedule)("afterRender", () => {
          if (opts.refreshHeight) {
            this.refreshHeight();
          }
          if (opts.ensureAtEnd) {
            this.ensureCaretAtEnd();
          }
          if (this.capabilities.isIpadOS || this.site.mobileView) {
            return;
          }
          if (opts.addText) {
            this.textManipulation.addText(this.textManipulation.getSelected(), opts.addText);
          }
          this.textManipulation.blurAndFocus();
        });
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "focus", [_decorators.bind]))();
    ensureCaretAtEnd() {
      (0, _runloop.schedule)("afterRender", () => {
        this.textarea.setSelectionRange(this.textarea.value.length, this.textarea.value.length);
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "ensureCaretAtEnd", [_decorators.bind]))();
    refreshHeight() {
      (0, _runloop.schedule)("afterRender", () => {
        // this is a quirk which forces us to `auto` first or textarea
        // won't resize
        this.textarea.style.height = "auto";

        // +1 is to workaround a rounding error visible on electron
        // causing scrollbars to show when they shouldn’t
        this.textarea.style.height = this.textarea.scrollHeight + 1 + "px";
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "refreshHeight", [_decorators.bind]))();
    getSelected() {
      return this.textManipulation.getSelected(...arguments);
    }
    static #_8 = (() => dt7948.n(this.prototype, "getSelected", [_decorators.bind]))();
    applySurround() {
      return this.textManipulation.applySurround(...arguments);
    }
    static #_9 = (() => dt7948.n(this.prototype, "applySurround", [_decorators.bind]))();
    addText() {
      return this.textManipulation.addText(...arguments);
    }
    static #_10 = (() => dt7948.n(this.prototype, "addText", [_decorators.bind]))();
    isInside() {
      return this.textManipulation.isInside(...arguments);
    }
    static #_11 = (() => dt7948.n(this.prototype, "isInside", [_decorators.bind]))();
    emojiSelected(code) {
      this.textManipulation.emojiSelected(code);
    }
  }
  _exports.default = TextareaInteractor;
});