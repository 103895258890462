define("discourse/plugins/chat/discourse/lib/chat-message-interactor", ["exports", "@glimmer/tracking", "@ember/object", "@ember/owner", "@ember/service", "discourse/components/emoji-picker/detached", "discourse/components/modal/bookmark", "discourse/components/modal/flag", "discourse/lib/ajax-error", "discourse/lib/bookmark-form-data", "discourse/lib/utilities", "discourse/models/bookmark", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat-message", "discourse/plugins/chat/discourse/lib/chat-message-flag", "discourse/plugins/chat/discourse/models/chat-message", "discourse/plugins/chat/discourse/models/chat-message-reaction"], function (_exports, _tracking, _object, _owner, _service, _detached, _bookmark, _flag2, _ajaxError, _bookmarkFormData, _utilities, _bookmark2, _getUrl, _decorators, _discourseI18n, _chatMessage, _chatMessageFlag, _chatMessage2, _chatMessageReaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.removeChatComposerSecondaryActions = removeChatComposerSecondaryActions;
  _exports.resetRemovedChatComposerSecondaryActions = resetRemovedChatComposerSecondaryActions;
  const removedSecondaryActions = new Set();
  function removeChatComposerSecondaryActions(actionIds) {
    actionIds.forEach(id => removedSecondaryActions.add(id));
  }
  function resetRemovedChatComposerSecondaryActions() {
    removedSecondaryActions.clear();
  }
  class ChatemojiReactions {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "chatChannelComposer", [_service.service]))();
    #chatChannelComposer = (() => (dt7948.i(this, "chatChannelComposer"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "chatThreadComposer", [_service.service]))();
    #chatThreadComposer = (() => (dt7948.i(this, "chatThreadComposer"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "chatChannelPane", [_service.service]))();
    #chatChannelPane = (() => (dt7948.i(this, "chatChannelPane"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "chatThreadPane", [_service.service]))();
    #chatThreadPane = (() => (dt7948.i(this, "chatThreadPane"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "capabilities", [_service.service]))();
    #capabilities = (() => (dt7948.i(this, "capabilities"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "menu", [_service.service]))();
    #menu = (() => (dt7948.i(this, "menu"), void 0))();
    static #_15 = (() => dt7948.g(this.prototype, "toasts", [_service.service]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    static #_16 = (() => dt7948.g(this.prototype, "interactedChatMessage", [_service.service]))();
    #interactedChatMessage = (() => (dt7948.i(this, "interactedChatMessage"), void 0))();
    static #_17 = (() => dt7948.g(this.prototype, "message", [_tracking.tracked], function () {
      return null;
    }))();
    #message = (() => (dt7948.i(this, "message"), void 0))();
    static #_18 = (() => dt7948.g(this.prototype, "context", [_tracking.tracked], function () {
      return null;
    }))();
    #context = (() => (dt7948.i(this, "context"), void 0))();
    constructor(owner, message, context) {
      (0, _owner.setOwner)(this, owner);
      this.message = message;
      this.context = context;
    }
    get pane() {
      return this.context === _chatMessage.MESSAGE_CONTEXT_THREAD ? this.chatThreadPane : this.chatChannelPane;
    }
    get canEdit() {
      return !this.message.deletedAt && this.currentUser.id === this.message.user.id && this.message.channel?.canModifyMessages?.(this.currentUser);
    }
    get canInteractWithMessage() {
      return !this.message?.deletedAt && this.message?.channel?.canModifyMessages(this.currentUser);
    }
    get canRestoreMessage() {
      return this.message?.deletedAt && (this.currentUser.staff || this.message?.user?.id === this.currentUser.id && this.message?.deletedById === this.currentUser.id) && this.message.channel?.canModifyMessages?.(this.currentUser);
    }
    get canBookmark() {
      return this.message?.channel?.canModifyMessages?.(this.currentUser);
    }
    get canReply() {
      return this.canInteractWithMessage && this.context !== _chatMessage.MESSAGE_CONTEXT_THREAD;
    }
    get canReact() {
      return this.canInteractWithMessage;
    }
    get canFlagMessage() {
      return this.currentUser.id !== this.message?.user?.id && this.message?.userFlagStatus === undefined && this.message.channel?.canFlag && !this.message?.chatWebhookEvent && !this.message?.deletedAt;
    }
    get canRebakeMessage() {
      return this.currentUser.staff && this.message.channel?.canModifyMessages?.(this.currentUser);
    }
    get canDeleteMessage() {
      return this.canDelete && !this.message?.deletedAt && this.message.channel?.canModifyMessages?.(this.currentUser);
    }
    get canDelete() {
      return this.currentUser.id === this.message.user.id ? this.message.channel?.canDeleteSelf : this.message.channel?.canDeleteOthers;
    }
    get composer() {
      return this.context === _chatMessage.MESSAGE_CONTEXT_THREAD ? this.chatThreadComposer : this.chatChannelComposer;
    }
    get secondaryActions() {
      const buttons = [];
      buttons.push({
        id: "copyLink",
        name: (0, _discourseI18n.i18n)("chat.copy_link"),
        icon: "link"
      });
      if (this.site.mobileView) {
        buttons.push({
          id: "copyText",
          name: (0, _discourseI18n.i18n)("chat.copy_text"),
          icon: "clipboard"
        });
      }
      if (this.canEdit) {
        buttons.push({
          id: "edit",
          name: (0, _discourseI18n.i18n)("chat.edit"),
          icon: "pencil"
        });
      }
      if (!this.pane.selectingMessages) {
        buttons.push({
          id: "select",
          name: (0, _discourseI18n.i18n)("chat.select"),
          icon: "list-check"
        });
      }
      if (this.canFlagMessage) {
        buttons.push({
          id: "flag",
          name: (0, _discourseI18n.i18n)("chat.flag"),
          icon: "flag"
        });
      }
      if (this.canDeleteMessage) {
        buttons.push({
          id: "delete",
          name: (0, _discourseI18n.i18n)("chat.delete"),
          icon: "trash-can"
        });
      }
      if (this.canRestoreMessage) {
        buttons.push({
          id: "restore",
          name: (0, _discourseI18n.i18n)("chat.restore"),
          icon: "arrow-rotate-left"
        });
      }
      if (this.canRebakeMessage) {
        buttons.push({
          id: "rebake",
          name: (0, _discourseI18n.i18n)("chat.rebake_message"),
          icon: "rotate"
        });
      }
      return buttons.reject(button => removedSecondaryActions.has(button.id));
    }
    select() {
      let checked = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.message.selected = checked;
      this.pane.onSelectMessage(this.message);
    }
    bulkSelect(checked) {
      const manager = this.message.manager;
      const lastSelectedIndex = manager.findIndexOfMessage(this.pane.lastSelectedMessage.id);
      const newlySelectedIndex = manager.findIndexOfMessage(this.message.id);
      const sortedIndices = [lastSelectedIndex, newlySelectedIndex].sort((a, b) => a - b);
      for (let i = sortedIndices[0]; i <= sortedIndices[1]; i++) {
        manager.messages[i].selected = checked;
      }
    }
    copyText() {
      (0, _utilities.clipboardCopy)(this.message.message);
      this.toasts.success({
        duration: 3000,
        data: {
          message: (0, _discourseI18n.i18n)("chat.text_copied")
        }
      });
    }
    copyLink() {
      const {
        protocol,
        host
      } = window.location;
      const channelId = this.message.channel.id;
      const threadId = this.message.thread?.id;
      let url;
      if (this.context === _chatMessage.MESSAGE_CONTEXT_THREAD && threadId) {
        url = (0, _getUrl.default)(`/chat/c/-/${channelId}/t/${threadId}/${this.message.id}`);
      } else {
        url = (0, _getUrl.default)(`/chat/c/-/${channelId}/${this.message.id}`);
      }
      url = url.indexOf("/") === 0 ? protocol + "//" + host + url : url;
      (0, _utilities.clipboardCopy)(url);
      this.toasts.success({
        duration: 1500,
        data: {
          message: (0, _discourseI18n.i18n)("chat.link_copied")
        }
      });
    }
    react(emoji, reactAction) {
      if (!this.chat.userCanInteractWithChat) {
        return;
      }
      if (this.pane.reacting) {
        return;
      }
      if (this.capabilities.userHasBeenActive && this.capabilities.canVibrate) {
        navigator.vibrate(5);
      }
      if (this.site.mobileView) {
        this.chat.activeMessage = null;
      }
      this.pane.reacting = true;
      this.message.react(emoji, reactAction, this.currentUser, this.currentUser.id);
      return this.chatApi.publishReaction(this.message.channel.id, this.message.id, emoji, reactAction).catch(errResult => {
        (0, _ajaxError.popupAjaxError)(errResult);
        this.message.react(emoji, _chatMessageReaction.REACTIONS.remove, this.currentUser, this.currentUser.id);
      }).finally(() => {
        this.pane.reacting = false;
      });
    }
    static #_19 = (() => dt7948.n(this.prototype, "react", [_object.action]))();
    toggleBookmark() {
      // somehow, this works around a low-level chrome rendering issue which
      // causes a complete browser crash when saving/deleting bookmarks in chat.
      // Error message: "Check failed: !NeedsToUpdateCachedValues()."
      // Internal topic: t/143485
      // Hopefully, this can be dropped in future chrome versions
      document.activeElement?.blur();
      this.modal.show(_bookmark.default, {
        model: {
          bookmark: new _bookmarkFormData.BookmarkFormData(this.message.bookmark || _bookmark2.default.createFor(this.currentUser, "Chat::Message", this.message.id)),
          afterSave: bookmarkFormData => {
            const bookmark = _bookmark2.default.create(bookmarkFormData.saveData);
            this.message.bookmark = bookmark;
            this.appEvents.trigger("bookmarks:changed", bookmarkFormData.saveData, bookmark.attachedTo());
          },
          afterDelete: () => {
            this.message.bookmark = null;
          }
        }
      });
    }
    static #_20 = (() => dt7948.n(this.prototype, "toggleBookmark", [_object.action]))();
    flag() {
      const model = new _chatMessage2.default(this.message.channel, this.message);
      model.username = this.message.user?.username;
      model.user_id = this.message.user?.id;
      this.modal.show(_flag2.default, {
        model: {
          flagTarget: new _chatMessageFlag.default((0, _owner.getOwner)(this)),
          flagModel: model,
          setHidden: () => model.set("hidden", true)
        }
      });
    }
    static #_21 = (() => dt7948.n(this.prototype, "flag", [_object.action]))();
    delete() {
      return this.chatApi.trashMessage(this.message.channel.id, this.message.id).catch(_ajaxError.popupAjaxError);
    }
    static #_22 = (() => dt7948.n(this.prototype, "delete", [_object.action]))();
    restore() {
      return this.chatApi.restoreMessage(this.message.channel.id, this.message.id).catch(_ajaxError.popupAjaxError);
    }
    static #_23 = (() => dt7948.n(this.prototype, "restore", [_object.action]))();
    rebake() {
      return this.chatApi.rebakeMessage(this.message.channel.id, this.message.id).catch(_ajaxError.popupAjaxError);
    }
    static #_24 = (() => dt7948.n(this.prototype, "rebake", [_object.action]))();
    reply() {
      this.composer.replyTo(this.message);
    }
    static #_25 = (() => dt7948.n(this.prototype, "reply", [_object.action]))();
    edit() {
      this.composer.edit(this.message);
    }
    static #_26 = (() => dt7948.n(this.prototype, "edit", [_object.action]))();
    async openEmojiPicker(trigger) {
      this.interactedChatMessage.emojiPickerOpen = true;
      await this.menu.show(trigger, {
        identifier: "emoji-picker",
        groupIdentifier: "emoji-picker",
        component: _detached.default,
        onClose: () => {
          this.interactedChatMessage.emojiPickerOpen = false;
        },
        data: {
          context: `channel_${this.message.channel.id}`,
          didSelectEmoji: emoji => {
            this.selectReaction(emoji);
          }
        }
      });
    }
    static #_27 = (() => dt7948.n(this.prototype, "openEmojiPicker", [_object.action]))();
    async closeEmojiPicker() {
      await this.menu.close("emoji-picker");
      this.interactedChatMessage.emojiPickerOpen = false;
    }
    static #_28 = (() => dt7948.n(this.prototype, "closeEmojiPicker", [_object.action]))();
    selectReaction(emoji) {
      if (!this.chat.userCanInteractWithChat) {
        return;
      }
      this.react(emoji, _chatMessageReaction.REACTIONS.add);
    }
    static #_29 = (() => dt7948.n(this.prototype, "selectReaction", [_decorators.bind]))();
    handleSecondaryActions(id) {
      this[id](this.message);
    }
    static #_30 = (() => dt7948.n(this.prototype, "handleSecondaryActions", [_object.action]))();
  }
  _exports.default = ChatemojiReactions;
});