define("discourse/plugins/chat/discourse/initializers/chat-setup", ["exports", "@ember/owner", "@ember/service", "discourse/components/emoji-picker/detached", "discourse/lib/formatter", "discourse/lib/plugin-api", "discourse-common/lib/get-owner", "discourse-common/lib/icon-library", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/lib/chat-composer-buttons", "discourse/plugins/chat/discourse/lib/hashtag-types/channel", "discourse/plugins/chat/discourse/components/chat/header/icon", "discourse/plugins/chat/discourse/components/styleguide/organisms/chat"], function (_exports, _owner, _service, _detached, _formatter, _pluginApi, _getOwner, _iconLibrary, _decorators, _discourseI18n, _chatComposerButtons, _channel, _icon, _chat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let _lastForcedRefreshAt;
  const MIN_REFRESH_DURATION_MS = 180000; // 3 minutes

  (0, _iconLibrary.replaceIcon)("d-chat", "comment");
  class ChatSetupInit {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatService", [(0, _service.service)("chat")]))();
    #chatService = (() => (dt7948.i(this, "chatService"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatHistory", [_service.service]))();
    #chatHistory = (() => (dt7948.i(this, "chatHistory"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    constructor(owner) {
      (0, _owner.setOwner)(this, owner);
      this.appEvents.on("discourse:focus-changed", this, "_handleFocusChanged");
      (0, _pluginApi.withPluginApi)("0.12.1", api => {
        api.addAboutPageActivity("chat_messages", periods => {
          const count = periods["7_days"];
          if (count) {
            return {
              icon: "comment-dots",
              class: "chat-messages",
              activityText: (0, _discourseI18n.i18n)("about.activities.chat_messages", {
                count,
                formatted_number: (0, _formatter.number)(count)
              }),
              period: (0, _discourseI18n.i18n)("about.activities.periods.last_7_days")
            };
          }
        });
        if (!this.chatService.userCanChat) {
          return;
        }
        api.onPageChange(path => {
          const route = this.router.recognize(path);
          if (route.name.startsWith("chat.")) {
            this.chatHistory.visit(route);
          }
        });
        api.registerHashtagType("channel", new _channel.default(owner));
        if (this.siteSettings.enable_emoji) {
          api.registerChatComposerButton({
            label: "chat.emoji",
            id: "emoji",
            class: "chat-emoji-btn",
            icon: "discourse-emojis",
            position: "dropdown",
            displayed: owner.lookup("service:site").mobileView,
            action(context) {
              const didSelectEmoji = emoji => {
                const composer = owner.lookup(`service:chat-${context}-composer`);
                composer.textarea.addText(composer.textarea.getSelected(), `:${emoji}:`);
              };
              owner.lookup("service:menu").show(document.body, {
                identifier: "emoji-picker",
                groupIdentifier: "emoji-picker",
                component: _detached.default,
                modalForMobile: true,
                data: {
                  context: "chat",
                  didSelectEmoji
                }
              });
            }
          });
        }
        api.registerChatComposerButton({
          id: "chat-upload-btn",
          icon: "far-image",
          label: "chat.upload",
          position: "dropdown",
          action: "uploadClicked",
          dependentKeys: ["canAttachUploads"],
          displayed() {
            return this.canAttachUploads;
          }
        });
        if (this.siteSettings.discourse_local_dates_enabled) {
          api.registerChatComposerButton({
            label: "discourse_local_dates.title",
            id: "local-dates",
            class: "chat-local-dates-btn",
            icon: "calendar-days",
            position: "dropdown",
            action() {
              this.insertDiscourseLocalDate();
            }
          });
        }

        // we want to decorate the chat quote dates regardless
        // of whether the current user has chat enabled
        api.decorateCookedElement(elem => {
          const currentUser = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:current-user");
          const currentUserTimezone = currentUser?.user_option?.timezone;
          const chatTranscriptElements = elem.querySelectorAll(".chat-transcript");
          chatTranscriptElements.forEach(el => {
            const dateTimeRaw = el.dataset["datetime"];
            const dateTimeEl = el.querySelector(".chat-transcript-datetime a, .chat-transcript-datetime span");
            if (currentUserTimezone) {
              dateTimeEl.innerText = moment.tz(dateTimeRaw, currentUserTimezone).format((0, _discourseI18n.i18n)("dates.long_no_year"));
            } else {
              dateTimeEl.innerText = moment(dateTimeRaw).format((0, _discourseI18n.i18n)("dates.long_no_year"));
            }
            dateTimeEl.dataset.dateFormatted = true;
          });
        });
        if (!this.chatService.userCanChat) {
          return;
        }
        document.body.classList.add("chat-enabled");
        this.chatService.loadChannels();
        const chatNotificationManager = owner.lookup("service:chat-notification-manager");
        chatNotificationManager.start();
        if (!this._registeredDocumentTitleCountCallback) {
          api.addDocumentTitleCounter(this.documentTitleCountCallback);
          this._registeredDocumentTitleCountCallback = true;
        }
        api.addCardClickListenerSelector(".chat-drawer-outlet");
        if (this.chatService.userCanChat) {
          api.headerIcons.add("chat", _icon.default);
        }
        api.addStyleguideSection?.({
          component: _chat.default,
          category: "organisms",
          id: "chat"
        });
      });
    }
    documentTitleCountCallback() {
      return this.chatService.getDocumentTitleCount();
    }
    static #_8 = (() => dt7948.n(this.prototype, "documentTitleCountCallback", [_decorators.bind]))();
    teardown() {
      this.appEvents.off("discourse:focus-changed", this, "_handleFocusChanged");
      if (!this.chatService.userCanChat) {
        return;
      }
      _lastForcedRefreshAt = null;
      (0, _chatComposerButtons.clearChatComposerButtons)();
    }
    _handleFocusChanged(hasFocus) {
      if (!this.chatService.userCanChat) {
        return;
      }
      if (!hasFocus) {
        _lastForcedRefreshAt = Date.now();
        return;
      }
      _lastForcedRefreshAt = _lastForcedRefreshAt || Date.now();
      const duration = Date.now() - _lastForcedRefreshAt;
      if (duration <= MIN_REFRESH_DURATION_MS) {
        return;
      }
      _lastForcedRefreshAt = Date.now();
    }
    static #_9 = (() => dt7948.n(this.prototype, "_handleFocusChanged", [_decorators.bind]))();
  }
  var _default = _exports.default = {
    name: "chat-setup",
    before: "hashtag-css-generator",
    initialize(owner) {
      this.instance = new ChatSetupInit(owner);
    },
    teardown() {
      this.instance.teardown();
      this.instance = null;
    }
  };
});